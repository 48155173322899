<template>
    <div class='contentData'>
        <el-form :model="data" :rules='rules' ref="personRecord" label-width="140px" >
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>基础信息</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="姓名/组织" prop="name" required>
                   <el-input v-model="data.name" maxlength='15' placeholder="请输入姓名/组织" clearable></el-input>
                </el-form-item>
                <!--   -->
                <el-form-item label="身份" prop="role" required>
                    <el-input v-model="data.role" maxlength='15' placeholder="请输入身份" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="性别" prop="gender" required>
                   <el-select v-model="data.gender" placeholder="请选择性别">
                        <el-option label="男" value="男"></el-option>
                         <el-option label="女" value="女"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号" prop="idCard" required>
                    <el-input v-model="data.idCard" clearable placeholder="请输入身份证号"></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="身份证地址" prop="cardAddress" required>
                   <el-input v-model="data.cardAddress" maxlength="200" placeholder="请输入身份证地址" clearable></el-input>
                </el-form-item>
                <el-form-item label="现居住地址" prop="address" required>
                    <el-input v-model="data.address" maxlength="200" placeholder="请输入现居住地址" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="联系电话" prop="mobilePhone" required>
                    <el-input v-model="data.mobilePhone" maxlength="15" placeholder="请输入联系电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="开户行" prop="bankName" required>
                    <el-input v-model="data.bankName" maxlength='15' clearable placeholder="请输入开户行"></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="银行卡账号" prop="bankCardNo" required>
                    <el-input v-model="data.bankCardNo" maxlength="20" placeholder="请输入银行卡账号" clearable></el-input>
                </el-form-item>
            </div>
            <!-- 补贴种类 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>补贴种类</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="机具大类" prop="machineTypeId" required>
                     <el-select  v-model="data.machineTypeId" placeholder="请选择机具大类" @change='onChangeMachines($event, 0)'>
                        <el-option
                        v-for="item in machCategory"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机具小类" prop="machineSubTypeId" required>
                    <el-select  v-model="data.machineSubTypeId" placeholder="请选择机具小类"  @change='onChangeMachines($event, 1)'>
                        <el-option
                        v-for="item in machinesxList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="机具品目" prop="machineItem" required>
                    <el-select  v-model="data.machineItem" placeholder="请选择机具品目" @change='onChangeMachines($event, 2)'>
                        <el-option
                        v-for="item in thingList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生产企业" prop="manufacturer" required>
                    <el-select  v-model="data.manufacturer" placeholder="请选择生产企业" @change='onChangeMachines($event, 3)'>
                        <el-option
                        v-for="item in productionList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="机具名称" prop="machineName" required>
                    <el-select  v-model="data.machineName" placeholder="请选择机具名称" @change='onChangeMachines($event, 4)'>
                        <el-option
                        v-for="item in designationList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机具型号" prop="machineModel" required>
                    <el-select  v-model="data.machineModel" placeholder="请选择机具型号" @change='onChangeMachines($event, 5)'>
                        <el-option
                        v-for="item in versionList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="功率(千瓦)" prop="power">
                    <el-input v-model="data.power"  placeholder="无需填写" disabled></el-input>
                </el-form-item>
                <el-form-item label="中央补贴" prop="centralSubsidy">
                    <el-input v-model="data.centralSubsidy"  placeholder="无需填写" disabled></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="省级补贴" prop="provincialSubsidy">
                    <el-input v-model="data.provincialSubsidy" placeholder="无需填写" disabled></el-input>
                </el-form-item>
                <el-form-item label="市级补贴" prop="citySubsidy">
                    <el-input v-model="data.citySubsidy"  placeholder="无需填写" disabled></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="县级补贴" prop="countySubsidy">
                    <el-input v-model="data.countySubsidy" placeholder="无需填写" disabled></el-input>
                </el-form-item>
                 <el-form-item label="分档名称" prop="gradingName">
                    <el-input v-model="data.gradingName" placeholder="无需填写" disabled></el-input>
                </el-form-item>
                
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="数量" prop="number" required>
                    <el-input v-model.number="data.number" maxlength="9"  clearable placeholder="请填写购买数量"></el-input>
                </el-form-item>
                <el-form-item label="经销商" prop="agentName" required>
                    <el-input v-model="data.agentName" maxlength="15" placeholder="请填写经销商" clearable></el-input>
                </el-form-item>
                
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="销售总价（元）" prop="totalSales" required>
                    <el-input v-model="data.totalSales" maxlength='8' clearable placeholder="请填写销售总价"></el-input>
                </el-form-item>
            </div>
            <div class='dataCommon'>
                <el-form-item label="备注" prop="remark" id='remark'>
                    <el-input v-model="data.remark" maxlength='200' type='textarea' :autosize="{ minRows: 2, maxRows: 5}" placeholder="请填写备注" resize='none' clearable ></el-input>
                </el-form-item>
            </div>
            <!-- 出厂信息 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>出厂信息</span></div>
            
            <div class='flex dataCommon'>
                <el-form-item label="出厂编号" prop="factoryList" required>
                    <el-input v-model="data.factoryList" maxlength="40" placeholder="请输入出厂编号"></el-input>
                </el-form-item>
                <el-form-item label="发票号" prop="billInvoiceList" required >
                    <el-input v-model="data.billInvoiceList" maxlength="40" placeholder="请输入发票号" clearable></el-input>
                </el-form-item>
            </div>
             <!-- 报废信息 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>报废信息</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="报废证明编号" prop="scrapNumber" required>
                    <el-input v-model="data.scrapNumber" maxlength="40" placeholder="报废证明编号" clearable></el-input>
                </el-form-item>
                <el-form-item label="报废更新机号" prop="scrapUpdateModel" required>
                    <el-input v-model="data.scrapUpdateModel" maxlength="40"  placeholder="请填写报废更新机号" clearable></el-input>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="底盘号" prop="chassisNumber" required >
                    <el-input v-model="data.chassisNumber" maxlength="40" placeholder="请输入底盘号" clearable></el-input>
                </el-form-item>
                <el-form-item label="报废补贴额（元）" prop="scrapSubsidyTotal" required>
                    <el-input v-model="data.scrapSubsidyTotal"  maxlength="8" clearable placeholder="请填写报废补贴额"></el-input>
                </el-form-item>
            </div>
            <!-- 相关图片信息 -->
            <div class='title clearfix'><span class='first fl'></span><span class='second fl'>相关图片信息</span></div>
            <div class='flex dataCommon'>
                <el-form-item label="身份证照片" prop="idCardList" ref='idCardList'>
                    <uploadMutImg :moduleList='moduleList' ref='idCardList'  @changeImgList='changeIdCardList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="个人头像" prop="headList" ref='headList'>
                    <uploadMutImg :moduleList='moduleList' ref='headList' @changeImgList='changeHeadList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="铭牌图片" prop="nameplateList" ref='nameplateList'>
                    <uploadMutImg :moduleList='moduleList' ref='nameplateList' @changeImgList='changeNameplateList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="发票图片" prop="invoiceList" ref='invoiceList'>
                    <uploadMutImg :moduleList='moduleList' ref='invoiceList' @changeImgList='changeInvoiceList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='flex dataCommon'>
                <el-form-item label="人机合影" prop="manmachineList" ref='manmachineList'>
                    <uploadMutImg :moduleList='moduleList' ref='manmachineList' @changeImgList='changeManmachineList'></uploadMutImg>
                </el-form-item>
            </div>
            <div class='submit'>
              <el-button class='submitData' @click='addSubmit()'>提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Upload, Loading, Message, DatePicker, Dialog, MessageBox, Alert, InputNumber } from 'element-ui';
import { yyyymmdd, filter_empty_value } from '@/utils/utils';
import uploadMutImg from '@/component/uploadMutImg/index.vue';
Vue.use(Upload);
Vue.use(Loading);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(InputNumber);
const validateIdentityCard = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入身份证号'));
        return;
    }
    const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(value)) {
        callback(new Error('请输入正确的身份证号'));
    }
    callback();
};
const validatePhone = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请输入手机号码'));
        return;
    }
    const reg = /^[+]{0,1}(\d+)$/;
    if (!reg.test(value)) {
        callback(new Error('联系电话格式有误'));
    }
    callback();
};
const validateNumber =  (rule, value, callback) => {
    if (!value && value !== 0) {
        callback(new Error('数量不能为空'));
        return;
    }
    const reg = /^[1-9]\d{0,8}$/;
    if (!reg.test(value)) {
        callback(new Error('数量只能为数字并且不能为0开头'));
    }
    callback();
};
const validateTotalSales =  (rule, value, callback) => {
    let msg = '';
    let msg1 = '';
    if (rule.field === 'scrapSubsidyTotal' ) {
        msg = '报废补贴额不能为空';
        msg1 = '报废补贴额应为数字格式并保留两位小数';
    } else if (rule.field === 'totalSales') {
        msg = '销售总价不能为空';
        msg1 = '销售总价应为数字格式并保留两位小数';
    }
    if (!value) {
        callback(new Error(msg));
        return;
    }
    const reg = /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/;
    if (!reg.test(value)) {
        callback(new Error(msg1));
    }
    callback();
};

export default {
    components: {
        uploadMutImg
    },
    data() {
        return {
            rules: {
                name: [
                    { required: true, message: '姓名或者组织不能为空', trigger: 'blur'}
                ],
                role: [
                    { required: true, message: '身份信息不能为空', trigger: 'blur' }
                ],
                gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                idCard: [
                    { required: true, validator: validateIdentityCard }
                ],
                cardAddress: [
                    { required: true, message: '身份证地址不能为空', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '现居住地址不能为空', trigger: 'blur' }
                ],
                mobilePhone: [
                    { required: true, validator: validatePhone }
                ],
                bankName: [
                    { required: true, message: '开户行不能为空', trigger: 'blur' }
                ],
                bankCardNo: [
                    { required: true, message: '银行卡账号不能为空', trigger: 'blur' }
                ],
                machineTypeId: [
                    { required: true, message: '机具大类不能为空' }
                ],

                machineSubTypeId: [
                    { required: true, message: '机具小类不能为空' }
                ],
                machineItem: [
                    { required: true, message: '机具品目不能为空' }
                ],
                manufacturer: [
                    { required: true, message: '生产企业不能为空' }
                ],
                machineName: [
                    { required: true, message: '机具名称不能为空' }
                ],
                machineModel: [
                    { required: true, message: '机具型号不能为空' }
                ],
                number: [
                    { required: true, validator: validateNumber }
                ],
                agentName: [
                    { required: true, message: '经销商不能为空', trigger: 'blur' }
                ],
                totalSales: [
                    { required: true, validator: validateTotalSales }
                ],
                factoryList: [
                    { required: true, message: '出厂编号不能为空', trigger: 'blur' }
                ],
                billInvoiceList: [
                    { required: true, message: '发票号不能为空', trigger: 'blur' }
                ],
                scrapNumber: [
                    { required: true, message: '证明编号不能为空', trigger: 'blur' }
                ],
                scrapUpdateModel: [
                    { required: true, message: '更新机型不能为空', trigger: 'blur' }
                ],
                chassisNumber: [
                    { required: true, message: '底盘号不能为空', trigger: 'blur' }
                ],
                scrapSubsidyTotal: [
                    { required: true, validator: validateTotalSales }
                ],
                idCardList: [
                    { required: true, message: '请上传图片' }
                ],
                headList: [
                    { required: true, message: '请上传图片' }
                ],
                nameplateList: [
                    { required: true, message: '请上传图片' }
                ],
                invoiceList: [
                    { required: true, message: '请上传图片' }
                ],
                manmachineList: [
                    { required: true, message: '请上传图片' }
                ]
            },
            data: {
                address: '', // 现居住地址
                agentName: '', // 经销商
                bankCardNo: '', // 银行卡账号
                bankName: '', // 开户行
                billInvoiceList: '', // 发票号
                cardAddress: '', // 证件地址
                centralSubsidy: '', // 中央补贴
                chassisNumber: '', // 底盘号
                citySubsidy: '', // 市级补贴
                countySubsidy: '', // 县级补贴

                factoryList: '', // 出厂编号
                gender: '', // 性别
                headList: [], // 个人头像
                idCard: '', // 身份证号
                idCardList: [], // 身份证图片
                invoiceList: [], //  发票图片
                machineItem: '', // 机具品目
                machineModel: '', // 机具型号
                machineName: '', // 机具名称
                machineSubTypeId: '', // 机具小类

                machineTypeId: '', // 机具大类
                manmachineList: [], // 人机合影
                manufacturer: '', // 生产企业
                mobilePhone: '',  // 联系电话
                name: '', // 姓名/组织
                nameplateList: [], // 铭牌图片
                number: '', // 购买数量
                power: '', // 功率(KW)
                provincialSubsidy: '', // 省级补贴
                remark: '', // 备注

                role: '', // 身份
                scrapNumber: '', // 报废证明编号
                scrapSubsidyTotal: '', // 报废补贴额
                scrapUpdateModel: '', // 报废更新机型
                totalSales: '', // 销售总价（元）
                gradingName: '', // 分档名称
                userId: ""
            },
            timeOut: new Date(),
            moduleList: {
                module: 'subsidyDeclare',
                subModule: 'index'
            },
            machCategory: [], // 机具大类
            machinesxList: [], // 机具小类
            thingList: [], // 机具品目
            productionList: [], // 生产企业
            designationList: [], // 机具名称
            versionList: [] // 机具型号
        };
    },
    mounted() {
        this.implementsList(0, 0);
    },
    methods: {
        // 上传铭牌图片
        changeNameplateList(fileList) {
            this.data.nameplateList = this.getImgList(fileList);
        },
        // 上传发票图片
        changeInvoiceList(fileList) {
            this.data.invoiceList = this.getImgList(fileList);
        },
        // 上传个人头像
        changeHeadList(fileList) {
            this.data.headList = this.getImgList(fileList);
        },
        // 上传身份证照片
        changeIdCardList(fileList) {
            this.data.idCardList = this.getImgList(fileList);
        },
        // 上传人机合影照片
        changeManmachineList(fileList) {
            this.data.manmachineList = this.getImgList(fileList);
        },
        // 获取图片列表
        getImgList(fileList) {
            const files = [];
            if (fileList.length > 0) {
               fileList.forEach(item => {
                   files.push(item.filePath);
               });
            }
            return files;
        },
        // 机具列表请求
        async implementsList(id, type) {
            const param = {
                id,
                type
            };
            const api = this.$fetchApi.machineryList.api;
            const data = await this.$fetchData.fetchPost(param, api, 'json');
            if (data.code === '200') {
                if (type === 0) {
                    this.machCategory = data.result;
                    this.machinesxList = [];
                    this.thingList = [];
                    this.productionList = [];
                    this.designationList = [];
                    this.versionList = [];
                    this.data.machineSubTypeId = '';
                    this.data.machineItem = '';
                    this.data.manufacturer = '';
                    this.data.machineName = '';
                    this.data.machineModel = '';
                } else if (type === 1) {
                    this.machinesxList = data.result;
                    this.data.machineSubTypeId = '';
                    this.thingList = [];
                    this.productionList = [];
                    this.designationList = [];
                    this.versionList = [];
                    this.data.machineItem = '';
                    this.data.manufacturer = '';
                    this.data.machineName = '';
                    this.data.machineModel = '';
                } else if (type === 2) {
                    this.thingList = data.result;
                    this.productionList = [];
                    this.designationList = [];
                    this.versionList = [];
                    this.data.machineItem = '';
                    this.data.manufacturer = '';
                    this.data.machineName = '';
                    this.data.machineModel = '';
                } else if (type === 3) {
                    this.productionList = data.result;
                    this.designationList = [];
                    this.versionList = [];
                    this.data.manufacturer = '';
                    this.data.machineName = '';
                    this.data.machineModel = '';
                } else if (type === 4) {
                    this.designationList = data.result;
                    this.versionList = [];
                    this.data.machineName = '';
                    this.data.machineModel = '';
                } else if (type === 5) {
                    this.versionList = data.result;
                    this.data.machineModel = '';
                }
                this.data.power = '';
                this.data.centralSubsidy = '';
                this.data.provincialSubsidy = '';
                this.data.citySubsidy = '';
                this.data.countySubsidy = '';
                this.data.gradingName = '';
            }
        },
        // 选择机具大类
        onChangeMachines(data, type) {
            if ( type < 5 ) {
                this.implementsList(data, type + 1);
            } else {
                this.information(data);
            }
        },
        // 补贴信息
        async information(data) {
            const api = this.$fetchApi.machineryMessage.api + data;
            await this.$fetchData.fetchGet({}, api, "json").then(res => {
                if (res.code === "200") {
                    this.data.power = res.result.power;
                    this.data.centralSubsidy = res.result.centralSubsidy;
                    this.data.provincialSubsidy = res.result.provincialSubsidy;
                    this.data.citySubsidy = res.result.citySubsidy;
                    this.data.countySubsidy = res.result.countySubsidy;
                    this.data.gradingName = res.result.gradingName;
                }
            });
        },
        // 提交信息
        addSubmit() {
             if (new Date() - this.timeOut < 1000) {
                return true;
            }
            this.timeOut = new Date();
            this.$refs.personRecord.validate((valid) => {
                if (valid) {
                    this.onSubmitMachinery();
                } else {
                    window.scroll(0, 400);
                    return false;
                }
            });
        },
        async onSubmitMachinery() {
            const loadingInstance = Loading.service({
                fullscreen: true,
                background: 'rgba(0,0,0,.4)'
            });
            const param = JSON.parse(JSON.stringify(this.data));
            param.billInvoiceList = [this.data.billInvoiceList];
            param.factoryList = [this.data.factoryList];
            const api = this.$fetchApi.machinerySubmit.api;
            const data = await this.$fetchData.fetchPost(param, api, "json");
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
            });
            if (data.code === '200' ) {
                MessageBox.confirm('您已提交申请', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success'
                })
            }
        }
    },
    watch: {
        'data.headList': {
            handler(val) {
                if (val.length > 0) {
                    this.rules.headList = [];
                    this.$refs['headList'].clearValidate();
                } else {
                     this.rules.headList = [{ required: true, message: '请上传图片' }];
                }
            }
        },
        'data.idCardList': {
            handler(val) {
                if (val.length > 0) {
                    this.rules.idCardList = [];
                    this.$refs['idCardList'].clearValidate();
                } else {
                     this.rules.idCardList = [{ required: true, message: '请上传图片' }];
                }
            }
        },
        'data.nameplateList': {
            handler(val) {
                if (val.length > 0) {
                    this.rules.nameplateList = [];
                    this.$refs['nameplateList'].clearValidate();
                } else {
                     this.rules.nameplateList = [{ required: true, message: '请上传图片' }];
                }
            }
        },
        'data.invoiceList': {
            handler(val) {
                if (val.length > 0) {
                    this.rules.invoiceList = [];
                    this.$refs['invoiceList'].clearValidate();
                } else {
                     this.rules.invoiceList = [{ required: true, message: '请上传图片' }];
                }
            }
        },
        'data.manmachineList': {
            handler(val) {
                if (val.length > 0) {
                    this.rules.manmachineList = [];
                    this.$refs['manmachineList'].clearValidate();
                } else {
                     this.rules.manmachineList = [{ required: true, message: '请上传图片' }];
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
    @import './common.less';
    #remark{
        width:90%;
    }
</style>